<template>
    <div class="container">
       <!--  
           
           <Message :msg="msg" v-show="msg"/> 
           
         <div class="alert alert-success" role="alert">
            A simple success alert—check it out!
         </div>
           
           -->

        <div v-show="msg" class="alert"  :class="tipoAlerta" role="alert">
            <h5>{{msg}}</h5>
        </div>

      
        <div class="modal-background" v-if="showModal">
            <div class="modal">
                <div class="modal-header">
                    <h2>Alterar Senha</h2>
                </div>
                <div class="modal-content">
                    <input type="password" v-model="password" placeholder="Senha">
                    <input type="password" v-model="confirmPassword" placeholder="Confirmar Senha">
                    <button @click="updatePassword(user_id)" class="modal-btn">Atualizar Senha</button>
                    <button @click="cancelaAlterarSenha">Cancelar</button>                
                </div>
            </div>
        </div>      
       
       
        <!--Listagem                              

        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Listar</button>
                <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Adicionar</button>
                <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Editar</button>
                
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                
                
          
          
          
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <p>Adicionar usuário</p>
            </div>
            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <p>Teste 3</p>
            </div>
       
        </div>



                <nav aria-label="...">
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link" href="#" tabindex="-1">Anterior</a></li>
                        <li  v-for="pagina in totalPaginas()" :key="pagina"  :click="getDadosPagina(pagina)" class="page-item"><a class="page-link" href="#">{{pagina}}</a></li>
                        <li class="page-item"><a class="page-link" href="#">Próximo</a></li>
                    </ul>
                </nav>



         -->

        <div>
            <h3>Gerenciar Usuários</h3> 
            
        </div>

        <div class="painel-superior">

            <div v-if="tOp == 'view'">
               <button  class="novo-btn" @click="novoUser">Novo</button>
            </div>
           
            <div v-if="tOp == 'update' || tOp == 'create'">
               <button class="novo-btn" @click="salvarUser">Salvar</button>
               <button class="novo-btn" @click="cancelarUser">Cancelar</button> 
            </div>
           
            <div v-if="tOp == 'read'">
               
               <button class="novo-btn" @click="cancelarUser">Sair</button> 
            </div>


        </div>

        <div id="list-table" v-if="tOp == 'view'">
           
            <div>
                <br /><br />
                <!--
                    <p>Total de páginas {{totalPaginas}}</p>

                -->
               
                <div id="head-table">
                    <div class="order-id"></div>
                    <div>Nome Usuário</div>
                    <div>Tipo Usuário</div> 
                    <div>Ações</div>  
                </div>
               
               
                <div id="det-rows">
                    <div class="det-row" v-for="user in users" :key="user.id">
                        <div class="order-number">{{user.id}}</div>
                        <div>{{user.nomeusuario}}</div>
                        <div>{{user.tipousuario}}</div>
                        
                        <div class="button-container" >
                            <button class="mostrar-btn" @click="mostrarUser(user.id)">Consultar</button>
                            <button class="alterar-btn" @click="carregarUser(user.id)">Alterar</button>
                            <button class="delete-btn" @click="deleteUser(user.id)">Excluir</button>
                            <button class="mostrar-btn" @click="alterarSenha(user.id)">Senha</button>
                       
                        </div>
                    </div> 
                    
                    <nav aria-label="...">
                        <ul class="pagination">
                            <li  @click="paginaAnterior()"  class="page-item"><a class="page-link" href="#" tabindex="-1">Anterior</a></li>
                            <li  v-for="pagina in totalPaginas" :key="pagina"    @click="calcOffSet(pagina)" class="page-item" :class="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                            <li  @click="proximaPagina()" class="page-item"><a class="page-link" href="#">Próximo</a></li>
                        </ul>
                    </nav>
                                  
                
                </div>

            </div>

        </div> 
        
        <div v-if="tOp != 'view'">

            <p> <strong>cadastro de usuário</strong> </p>
            
            <form>
           
                <div class="form-row">
                    <div class="form-group col-md-12">
                    <label for="celular">WhatsApp</label>
                    <input :readonly="readonly" name="celular" v-model="celular" type="text" class="form-control" id="celular" placeholder="Whatsapp">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                    <label for="nomeusuario">Nome Usuário</label>
                    <input :readonly="readonly"  name="nomeusuario" v-model="nomeusuario" type="text" class="form-control" id="nomeusuario" placeholder="Nome Usuário">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-12">
                    <label for="nomeapresentacao">Nome</label>
                    <input :readonly="readonly"  name="nomeapresentacao" v-model="nomeapresentacao" type="text" class="form-control" id="nomeapresentacao" placeholder="Nome">
                    </div>
                </div>



                <div class="form-row">
                    <div class="form-group col-md-12">
                    <label for="email">Email</label>
                    <input :readonly="readonly" name="email" v-model="email" type="email" class="form-control" id="email" placeholder="email">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                    <label for="telefone">Telefone</label>
                    <input :readonly="readonly" name="telefone" v-model="telefone" type="text" class="form-control" id="telefone" placeholder="Telefone">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-8">
                    <label for="publicToken">Public Token</label>
                    <input :readonly="readonly" name="publicToken"  v-model="publicToken"  type="text" class="form-control" id="publicToken" placeholder="Public Token">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-8">
                    <label for="deviceToken">Device Token</label>
                    <input :readonly="readonly" name="deviceToken"  v-model="deviceToken"  type="text" class="form-control" id="deviceToken" placeholder="Device Token">
                    </div>
                </div>


                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="tipousuario">Tipo usuário</label>
                        <select :readonly="readonly"  name="tipousuario" v-model="tipousuario" id="tipousuario" class="form-control">
                            <!--

                               <option selected>Escolher...</option>

                            -->
                            
                            
                            <option>ADMIN</option>
                            <option>USUÁRIO</option>
                            <option>COLABORADOR</option>
                        </select>
                    </div>
                </div>    





            </form>

        </div>

    </div>
</template>

<script>

import Message from '../../Message.vue'
import {urlAPI, tipoOp} from '../../../config.js'




export default {
    name:"UserListForm",
    Components:{
      Message 
    },
    data(){
        return{

            showModal: false,
            password: '',
            confirmPassword: '',

            users:null,
            user_id:null,
            msg:null,            
            tOp:null,
            tipoAlerta:null,
            readonly:false,

            nomeusuario:null,
            nomeapresentacao:null,
            email:null,
            telefone:null,
            tipousuario:null,
            senha:null,
            publicToken:null,
            deviceToken:null,
            celular:null,
            hashnumber:null,
            token:null,
            nivel:2,
            foto64:null,
            idadmin:null,
            pathfile:null,
            offset:0,
            limit:10,
            totalPaginas:0,
            paginaAtual:1,

            filtro:null,
            sort:null

        }
        
    },
    methods:{

        cancelaAlterarSenha(){
          this.password = '';
          this.confirmPassword = '';  
          this.showModal = false;
        },
        
        alterarSenha(id){
            this.user_id = id
            this.showModal = true
  
        },
       
        updatePassword(id) {
            if (this.password === this.confirmPassword) {
                // Lógica para atualizar a senha aqui
                
                this.atualizarSenha(id)

               
                this.showModal = false;
                this.password = '';
                this.confirmPassword = '';
            } else {
                
                alert('As senhas não coincidem!')
                console.log('As senhas não coincidem!');

            }
        },
        

        isActive(pagina){
            return pagina == this.paginaAtual ? 'active' : ''
        },

        proximaPagina(){
            if (this.paginaAtual >= this.totalPaginas){
                return
            }
            this.paginaAtual ++
            this.calcOffSet(this.paginaAtual)
        },
        
        paginaAnterior(){

            if (this.paginaAtual <= 1){
               return 
            }


            this.paginaAtual --


            this.calcOffSet(this.paginaAtual)
        },

        calcOffSet(pagina){

            this.offset = (pagina * this.limit) - this.limit
            
            this.getUsers()

            this.paginaAtual = pagina

            console.log('pagina ',pagina)
        },

        limparCampos(){
           
           this.user_id = null
           this.nomeusuario = ""
           this.nomeapresentacao = ""
           this.email = ""
           this.telefone = ""
           this.tipousuario = "ADMIN"
           this.senha = ""
           this.publicToken  = ""
           this.deviceToken = ""
           this.celular = ""
           this.hashnumber = ""
           this.token = ""
           this.nivel = 2
           this.foto64 = ""
           this.idadmin = this.$store.getters.getIdAdmin 
           this.pathfile = ""
           

        },        
        
        
        limparMsg(){
           this.msg = null
           this.tipoAlerta = null 
        },


        cancelarUser(){
            
            
            this.tOp = tipoOp.view
        },
        


        async atualizarSenha(idUsuario){

            let data = {
                
                idusuario:  idUsuario,
                pws:  this.password,
                token: this.$store.getters.getTokenAdmin             

            }

            console.log('ID-> ',data.idusuario,'Token-> ', data.token, 'pws-> ',data.pws)


            let dataJson = JSON.stringify(data)

            let endpoint;
            let methods;
            
            endpoint = '/users/update/pws' 
            methods = "PUT"

            try {
                const req = await fetch(urlAPI+endpoint,{
                    method:methods,
                    headers: {"content-type":"application/json"},
                    body:dataJson 
                })
            
                const res = await req.json()                
                
                console.log('Resposta: ',res)

                dataJson = JSON.stringify(res)

                console.log(dataJson)                
                
                if (res.Result == 'OK'){

                    this.tipoAlerta = "alert-success"
                    
                    this.msg = res.Message

                    //console.log(res.Message, id);
                    
                    
                    // clear message
                    setTimeout(() => this.limparMsg(), 3000)

                
                } else {

                    this.tipoAlerta = "alert-warning"

                    this.msg = res.Message
                    
                    
                    // clear message
                    setTimeout(() => this.limparMsg(), 3000)

                }
                
                let result = JSON.parse(dataJson)                
                
                console.log(this.msg)               
                
                console.log(result.Result, result.Message)

            
            } catch (error) {
                
                this.tipoAlerta = "alert-danger"
                this.msg = 'Ocorreu um erro ao processar informações' 
                
                console.log('Error: ',error)

                // clear message
                setTimeout(() => this.limparMsg(), 3000)


            }

  
  
        },


        async salvarUser(){

          let data = {
                
                id:  this.user_id,
                nomeusuario:  this.nomeusuario,
                nomeapresentacao: this.nomeapresentacao,
                email: this.email,
                telefone: this.telefone,
                tipousuario: this.tipousuario,
                senha: this.senha,
                public_token: this.publicToken,
                device_token: this.deviceToken,
                celular: this.celular,
                hashnumber:this.hashnumber,
                token:this.token,
                nivel: this.nivel,
                foto64: this.foto64,
                idadmin: this.idadmin,
                pathfile:  this.pathfile

           }

           let dataJson = JSON.stringify(data)

           let endpoint;
           let methods;
           
           if (this.tOp == tipoOp.create){
               endpoint = '/users/insert'
               methods = "POST"
               delete data.id //Para inclusão no banco é necessário excluir o id  
           }else if (this.tOp == tipoOp.update){
               endpoint = '/users/update' 
               methods = "PUT"
               
           } 

            try {
                const req = await fetch(urlAPI+endpoint,{
                    method:methods,
                    headers: {"content-type":"application/json"},
                    body:dataJson 
                })
           
                const res = await req.json()                
                
                console.log('<---->Resposta: ',res)

                dataJson = JSON.stringify(res)

                console.log(dataJson)                
                
                if (res.Result == 'OK'){

                   this.tipoAlerta = "alert-success"
                   
                   this.msg = res.Message

                    // clear message
                    setTimeout(() => this.limparMsg(), 3000)

                
                } else {

                    this.tipoAlerta = "alert-warning"

                    this.msg = res.Message
                    
                    if(dataJson.indexOf("idx_email") > 0){
                        
                        this.tipoAlerta = "alert-warning"

                        this.msg = "Email já cadastrado"
                    }              
                    
                    // clear message
                    setTimeout(() => this.limparMsg(), 3000)
                    


                }
                
                let result = JSON.parse(dataJson)                
                
                console.log(this.msg)               
               
               console.log(result.Result, result.Message)

               this.tOp = tipoOp.view

               this.getUsers()

               this.limparCampos()
           
           } catch (error) {
                
                this.tipoAlerta = "alert-danger"
                this.msg = 'Ocorreu um erro ao processar informações' 
                
                console.log('Error: ',error)

                // clear message
                setTimeout(() => this.limparMsg(), 3000)


           }

            
            
        },

        async getUsers(){
            console.log(urlAPI)
            
            
            
            //Ordenação
            this.sort = 'nomeusuario'
            
            //Filtrar por uma determinada informação 
            //this.filtro = "nomeusuario = 'master'"

            this.filtro = `nivel > 1 and idadmin = ${this.$store.getters.getIdAdmin}`
            
            try {

                 const req = await fetch(urlAPI+`/users/list/offset/${this.offset}/limit/${this.limit}/filtro/${this.filtro}/sort/${this.sort}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                     
                })

                const res = await req.json()
                
                this.users = res.usuarios 

                //this.totalGeralRegistro = res.totalGeralRegistro

                this.users != null ?  this.totalPaginas = Math.ceil(res.totalGeralRegistro / this.limit) : 0

                
                
                console.log(this.users)
                 
                
            } catch (error) {
                console.log("erro ao listar usuários", error)
            }


        },

       async carregarDados(id){

                const req = await fetch(urlAPI+`/users/find/${id}`,{
                    method:"GET",
                    headers: {"content-type":"application/json"},
                  
                     
                })

                const res = await req.json()
              
                //Carrega dados
                this.user_id = res.id
                this.nomeusuario = res.nomeusuario
                this.nomeapresentacao = res.nomeapresentacao
                this.email = res.email
                this.telefone = res.telefone
                this.tipousuario = res.tipousuario
                this.senha = res.senha
                this.publicToken = res.public_token,
                this.deviceToken = res.device_token,
                this.celular = res.celular
                this.idadmin = res.idadmin
                this.token = res.token


       },


        async deleteUser(id){         

             this.carregarDados(id)
            
            console.log(this.tipousuario)

            if (this.tipousuario == 'MASTER'){
               alert('Usuário não pode ser excluido') 
               return
            }

            const resultado = confirm("Deseja excluir o usuário " + "" + " ?");
            if (resultado !== true){
                return
            }

            try {

               const req = await fetch(urlAPI+`/users/delete/${id}`,{
                    method:"DELETE",
                  
                     
                })

                const res = await req.json()
                
                //this.users = res 

                console.log(res)

                console.log(id)

                //Dependendo da situação o correto é excluir a informação da tela ao invés de fazer nova requisição para o servidor
                this.getUsers()

                //Transforma JSON em String
                //let dataJson = JSON.stringify(res)
                
               //Transforma String em JSON 
               // let result = JSON.parse(dataJson)  

               this.tipoAlerta = "alert-success"
               
               this.msg=res.Message

               // clear message
               setTimeout(() => this.limparMsg(), 3000)

                
            } catch (error) {
                console.log(error)
            }
        },

        async mostrarUser(id){
            try{
                
                this.readonly = true
                
                this.carregarDados(id)

                this.tOp = tipoOp.read
                 
                console.log('operação ',this.tOp) 
            }
            catch(error){
                console.log(error)
            }

            console.log(id)
    
            
        },

        async carregarUser(id){
            try{
                
                this.readonly = false
                
                this.carregarDados(id)

                this.tOp = tipoOp.update
                 
                console.log('operação ',this.tOp) 
            }
            catch(error){
                console.log(error)
            }

            console.log(id)
    
            
        },

        novoUser(){
            try{
                this.readonly = false
                
                this.limparCampos()
                this.tOp = tipoOp.create
                console.log('operação ',this.tOp)                 
            }
            catch(error){
                console.log(error)
            }
            
        },



    },

    mounted(){        
        this.tOp = tipoOp.view

        //this.getDadosPagina(1)
        
        this.getUsers()

        
    }
}
</script>

<style scoped>
 
   #list-table{
       max-width: 1200px;
       margin: 0 auto;
   }
 

 
   #head-table, #det-rows, .det-row{
        display: flex;
       flex-wrap: wrap;
   }

   #head-table{
       font-weight: bold;
       padding: 12px;
       border-bottom: 3px solid #333;
   }

   .det-row{
       width: 100%;
       padding: 12px;
       border-bottom: 1px solid #ccc;
   }

   #head-table div, .det-row div{
       width: 25%
   }

   #head-table .order-id, #det-row .order-number{
       width: 5%;
   }


 .container{
      margin: 0 auto;
  }
   
   .order-id{
       margin: 0 auto;
   }

   .order-number{
       margin: 0 auto;
   } 



   .delete-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 auto;
      cursor: pointer;
      transition: .5s;
   }

   .delete-btn:hover{
       background-color: transparent;
       color:#222;
   }


   .mostrar-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px;
      cursor: pointer;
      transition: .5s;
      
   }

   .mostrar-btn:hover{
       background-color: transparent;
       color:#222;
   }

   .alterar-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px;
      cursor: pointer;
      transition: .5s;
      
   }

   .alterar-btn:hover{
       background-color: transparent;
       color:#222;
   }

   .novo-btn{
      background: #222;
      color:#fcba03;
      font-weight: bold;
      border:  2px solid #222;
      padding: 10px;
      font-size: 16px;
      margin: 0 10px auto;
      cursor: pointer;
      transition: .5s;
      min-width: 90px;
      
   }

   .novo-btn:hover{
       background-color: transparent;
       color:#222;
   }



   .painel-superior{
      
       float:right;
       
            
   } 

   .btn-custom{
       margin: 10 10 10 10;
   }



    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }  



   
    
  @media (max-width: 768px) {
  
 

  /*



   .modal {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
        width: 300px;
    }





  .modal-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }





  .det-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .order-number, div {
    flex: 1;
    padding: 5px;
  }
  
 .button-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
  }  
  
  

.modal {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  width: 300px;
}

.modal-header {
  background-color: #3498db;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-btn:hover {
  background-color: #2980b9;
}

  

   .modal-active{
     display:block;
   }





  */
  
  
}


</style>

